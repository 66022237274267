<template>
  <div>
    <v-row v-for="(contact, index) in contacts" :key="index">
      <v-col cols="3">
        <label class="card-title">{{ ownerLabel || 'Responsavel' }}</label>
        <v-text-field v-model="contact.owner" outlined :error-messages="errorMessages.contacts[index].owner" />
      </v-col>
      <v-col cols="2">
        <label class="card-title">Cargo</label>
        <v-text-field v-model="contact.role" outlined :error-messages="errorMessages.contacts[index].role" />
      </v-col>
      <v-col cols="3">
        <label class="card-title">E-mail</label>
        <v-text-field v-model="contact.email" outlined :error-messages="errorMessages.contacts[index].email" />
      </v-col>
      <v-col cols="3">
        <label class="card-title">Telefone</label>
        <v-text-field
          v-model="contact.phone"
          v-mask="['+55 (##) #####-####', '+55 (##) ####-####']"
          outlined
          :error-messages="errorMessages.contacts[index].phone"
        />
      </v-col>
      <v-col class="d-flex justify-space-around" cols="1">
        <v-icon class="btn-focus-action" color="primary" @click="contacts.push({})">add</v-icon>
        <v-icon v-if="contacts.length - 1" class="btn-focus-action" color="error" @click="removeContact(index)">delete</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    ownerLabel: {
      type: String,
      default: ''
    },
    validations: {
      type: Object,
      required: true
    },
    contacts: {
      type: Array,
      default: () => [{}]
    }
  },
  computed: {
    errorMessages() {
      const fields = ['contacts']
      const subFieldsContacts = ['phone', 'email', 'owner', 'role']

      return fields.reduce((errors, field) => {
        if (this.validations.contacts && this.validations.contacts.$error) {
          if (!this.validations.contacts.required) errors[field] = 'Campo obrigatório.'
        } else {
          errors[field] = ''
        }
        if (field === 'contacts') {
          errors[field] = []
          for (let i = 0; i < this.contacts.length; i++) {
            errors[field][i] = {}
            subFieldsContacts.forEach(subField => {
              if (subField === 'email') {
                if (this.validations.contacts.$each[i].email.$error) {
                  if (!this.validations.contacts.$each[i].email.email) {
                    errors[field][i][subField] = 'E-mail inválido.'
                  }
                  if (!this.validations.contacts.$each[i].email.required) {
                    errors[field][i][subField] = 'Campo obrigatório.'
                  }
                }
              } else if (subField === 'phone') {
                if (this.validations.contacts.$each[i].phone.$error) {
                  if (!this.validations.contacts.$each[i].phone.minLength || !this.validations.contacts.$each[i].phone.maxLength) {
                    errors[field][i][subField] = 'Telefone inválido.'
                  }
                  if (!this.validations.contacts.$each[i].phone.required) {
                    errors[field][i][subField] = 'Campo obrigatório.'
                  }
                }
              } else {
                if (this.validations.contacts.$each[i][subField].$error) {
                  if (!this.validations.contacts.$each[i][subField].required) {
                    errors[field][i][subField] = 'Campo obrigatório.'
                  }
                }
              }
            })
          }
        }
        return errors
      }, {})
    }
  },
  created() {
    if (!this.contacts.length) this.contacts.push({})
  },
  methods: {
    removeContact(index) {
      this.contacts.splice(index, 1)
    }
  }
}
</script>

<style lang="scss">
.btn-focus-action {
  height: min-content;
  display: flex;
  align-self: center;
}
</style>
